import React from "react";
import { observer } from "mobx-react";
import { Button } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { Page } from "@app/components/page";
import classes from "classnames";
import Avatar from "@app/components/user/avatar";
import Box from "@app/components/box/box";
import "./style/profile.scoped.scss";

import General from "./view/general";
import Settings from "./view/settings";
import Password from "./view/password";

import profile from "@app/state/store/profile";

const Profile = observer((props) => {
    var section = props.location.search?.replace(/^\?/, "");
    section = section || "general";

    // return empty page while loading
    if (!profile.data) {
        profile.load();
        return null;
    }

    /**
     * Return the correct section based on the parameters
     */
    const Section = (props) => {
        if (section === "password") {
            return <Password {...props} />;
        } else if (section === "settings") {
            return <Settings {...props} />;
        } else {
            return <General {...props} />;
        }
    };

    return (
        <Page className="profile">
            <Page.Header>
                <Page.Header.Left>
                    <Page.Title>Profile</Page.Title>
                </Page.Header.Left>

                <Page.Header.Right>
                    <Link to="/profile/edit">
                        <Button type="icon" loading={profile.busy} icon={<EditOutlined />}></Button>
                    </Link>
                </Page.Header.Right>
            </Page.Header>
            <Page.Body>
                <div className="wrapper">
                    <Box className="navigation" padding={false}>
                        <Avatar className="avatar" user={profile.data} size={120} />
                        <div className="links">
                            <Link
                                className={classes({ active: section === "general" })}
                                to="/profile?general"
                            >
                                Profile
                            </Link>
                            <Link
                                className={classes({ active: section === "password" })}
                                to="/profile?password"
                            >
                                Change Password
                            </Link>
                            <Link
                                className={classes({ active: section === "settings" })}
                                to="/profile?settings"
                            >
                                Settings
                            </Link>
                        </div>
                    </Box>
                    <Section className="section" />
                </div>
            </Page.Body>
        </Page>
    );
});

export default Profile;
