import React from "react";
import { Button, Tag } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

import Card from "@app/components/card/card";
import Avatar from "./avatar";
import format from "@app/lib/format";

import "./style/card.scoped.scss";

export default function UserCard({ user, onEdit, onRemove, children, ...rest }) {
    // Is user pending status?
    const isPending = user.status === "pending";

    // Generate name for pending users
    const fullName = isPending ? "Pending Invitation" : user.fullName;

    /**
     * Handle edit button click
     * @param {MouseEvent} event
     */
    const edit = (event) => {
        event.preventDefault();
        if (onEdit && !isPending) {
            onEdit(user, event);
        }
    };

    /**
     * Handle delete button click
     * @param {MouseEvent} event
     */
    const remove = (event) => {
        event.preventDefault();
        if (onRemove) {
            onRemove(user, event);
        }
    };

    return (
        <Card className="user-card" {...rest}>
            <div className="avatar">
                <Avatar user={user} size={60} />
            </div>
            <div className="details">
                <div className="name">{fullName}</div>
                <div className="email">{user.email}</div>
                <div className="roles">
                    {user.roles.map((role) => {
                        return (
                            <Tag color="blue" key={role}>
                                {format.role(role)}
                            </Tag>
                        );
                    })}
                </div>
            </div>
            <div className="actions">
                {!isPending && <Button onClick={edit} type="icon" icon={<EditOutlined />}></Button>}
                <Button onClick={remove} type="icon" danger icon={<DeleteOutlined />}></Button>
            </div>
        </Card>
    );
}
