import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { useParams } from "react-router-dom";
import { Page } from "@app/components/page";
import { Button, Spin } from "antd";
import { StarOutlined, StarFilled, CloseOutlined, LoadingOutlined } from "@ant-design/icons";
import classNames from "classnames";
import Filter from "@app/components/filter";
import EmptyList from "@app/components/list/empty";
import SearchSidebar from "@app/components/search/query/sidebar";

import Form from "@app/components/search/query/form";
import Feedback from "@app/components/search/query/feedback";
import Progress from "@app/components/search/progress";
import Result from "@app/components/search/result";
import "./query.scoped.scss";

import confirm from "@app/components/confirm";
import session from "@app/state/store/session";
import report from "@app/state/store/report";
import query from "@app/state/store/report/query";
import notify from "@app/components/notify/index";

const spinIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const Query = observer((props) => {
    const { project, id } = useParams();
    const saved = query.data?.status === "saved";
    const saving = query.data?.status === "saving";
    const editing = query.data?.editing;
    const imported = query.data?.imported || query.upload;
    const type = query.data?.type;
    const config = report.config || {};

    // initialize the state when component mounts.
    useEffect(() => {
        if (id) {
            query.load(id);
        } else {
            query.create();
        }

        return () => {
            query.reset();
        };
    }, [id]);

    // monitor the change of the search id
    useEffect(() => {
        if (query.data?._id && id !== query.data._id) {
            props.history.push(`./${query.data._id}`);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query.data?._id]);

    // do not render unless the search is created/loaded
    if (!query.data || query.data._id !== id) {
        return null;
    }

    const hasSearchResultError = (result) => {
        let hasError = false;

        if (result.verified === "failed") {
            notify.error("Unable to fetch Pubmed ID file; please upload.");
            hasError = true;
        } else if (result.error) {
            if (result.limit) {
                notify.error(
                    "The number of articles in this search is above the allowed limit. " +
                        "Please try narrowing the search results before saving the search.",
                );
            } else {
                notify.error("An error has occurred while saving the search.");
            }
            hasError = true;
        }

        return hasError;
    };

    /**
     * Toggle the search saved state
     */
    const toggleSaved = async () => {
        let result;

        if (editing) {
            result = await query.update(query.data._id);
            if (!hasSearchResultError(result)) {
                close();
            }
        } else if (saved) {
            let resume = await confirm("Are you sure you want to delete this search?");
            if (!resume) {
                return;
            }

            await query.exclude(query.data._id);
            close();
        } else {
            result = await query.include(query.data._id);

            // Don't notify if there are zero results
            if (query.results.length > 0) {
                hasSearchResultError(result);
            }

            query.load(id, { clear: false });
        }
    };

    /**
     * Handle the onSearch event to close the imported files because they get saved
     */
    const onSearch = () => {
        if (!query.searchPerformed) {
            query.searchPerformed = true;
        }
        if (editing && imported) {
            close();
        }
    };

    /**
     * Close the search
     */
    const close = () => {
        props.history.push(`/r/${project}/search`);
    };

    /**
     * Initiate the search edit
     */
    const editSearch = async () => {
        await query.edit(query.data._id);
    };

    /**
     * Render the search results
     */
    const Results = observer(() => {
        const processing = saving || query?.loading || query?.fetching;
        const importing = imported && editing;
        const title = imported ? "This search is pending" : "No Results Found";
        const emptyMessage = imported ? (
            <>
                The search has not been performed yet. Please click on the <b>Search</b> button
                above to see the search results.
            </>
        ) : (
            "No results were found using the search criteria."
        );

        if (
            !processing &&
            !importing &&
            !query.upload &&
            query.results.length === 0 &&
            query.data._id
        ) {
            return (
                <EmptyList title={title} show={!query.loading}>
                    {emptyMessage}
                </EmptyList>
            );
        }

        return (
            <div className="results">
                {query?.results.map((article, idx) => {
                    return (
                        <Result
                            article={article}
                            keywords={type ? config[type].keywords : []}
                            key={idx}
                        />
                    );
                })}
            </div>
        );
    });

    /**
     * Render the page title with icon
     */
    const Title = observer(() => {
        const toggled = saved || saving;
        const icon = toggled ? <StarFilled className="star" /> : <StarOutlined className="star" />;

        let savedLabel = "not saved";
        if (saved) {
            savedLabel = "saved";
        } else if (saving) {
            savedLabel = "saving";
        }

        return (
            <div className={classNames("page-title", { saved: toggled })}>
                <i>{icon}</i>
                <span className="title">Search</span>
                <label>({savedLabel})</label>
            </div>
        );
    });

    /**
     * Render the page title with icon
     */
    const SaveButton = observer(() => {
        // do not show it unless there is a query
        if (!id) {
            return null;
        }

        if (saved) {
            return (
                <Button onClick={toggleSaved} loading={query.loading} disabled={saving}>
                    Delete
                </Button>
            );
        } else {
            return (
                <Button
                    type="primary"
                    onClick={toggleSaved}
                    loading={query.loading}
                    disabled={saving || !query.searchPerformed}
                >
                    {editing ? "Update" : "Save"}
                </Button>
            );
        }
    });

    /**
     * Render the page title with icon
     */
    const SaveProgress = observer(() => {
        // do not show it unless there is a query
        if (!query.data.saving) {
            return null;
        }
        const verified = query.data.verifiedIds?.length;

        return (
            <>
                {verified ? (
                    <Spin indicator={spinIcon} />
                ) : (
                    <Progress value={query.data.progress} width={30} />
                )}
                <Page.Header.Separator />
            </>
        );
    });

    const EditButtons = observer(() => {
        if (!session.can("project.search") || report.readOnly) {
            return null;
        }

        if (!saved && imported) {
            return null;
        }

        return (
            <>
                <div className="button-group">
                    {saved && (
                        <Button
                            type="primary"
                            onClick={editSearch}
                            loading={query.loading}
                            disabled={saving}
                        >
                            Edit
                        </Button>
                    )}

                    {!imported && <SaveButton />}
                </div>
                <Page.Header.Separator />
            </>
        );
    });

    return (
        <Page className="query">
            <Page.Header title={<Title />}>
                <Page.Header.Right shrink>
                    <SaveProgress />

                    <Filter.Count filter={query.pager} />
                    <Filter.SmallPager filter={query.pager} />
                    <Page.Header.Separator />

                    <EditButtons />

                    <Button type="icon" onClick={close} icon={<CloseOutlined />}></Button>
                </Page.Header.Right>
            </Page.Header>

            <Page.Layout>
                <Page.Body filter={query.pager}>
                    <Form onSearch={onSearch} />
                    <Feedback />
                    <Results />
                </Page.Body>
                <SearchSidebar
                    search={id}
                    searchState={query}
                    filter={query.filter}
                    disabled={saved || saving}
                />
            </Page.Layout>

            <Page.Footer>
                <Page.Footer.Left>
                    <Filter.Pager filter={query.pager} />
                </Page.Footer.Left>
            </Page.Footer>
        </Page>
    );
});

export default Query;
