import React from "react";
import { observer } from "mobx-react";
import Filter from "@app/components/filter";
import Sidebar from "@app/components/filter/sidebar";
import {
    GoogleLanguages,
    LanguageCodes,
    PubMedLanguages,
    PubmedPublicationTypes,
    EuPmcPublicationTypes,
    Search,
} from "@app/constants";
import { Button } from "antd";
import { FileProtectOutlined } from "@ant-design/icons";

import template from "@app/assets/import-template.xlsx";
import "../style/filter.scoped.scss";

const QueryFilter = observer(({ filter, disabled }) => {
    const source = filter.getSource();
    const minYear = 1900;
    const maxYear = new Date().getFullYear();

    const publicationTypeList =
        source === Search.EUPMC ? EuPmcPublicationTypes : PubmedPublicationTypes;
    const publicationTypes = publicationTypeList.map((type) => {
        if (type === "---") {
            return { separator: true };
        } else {
            return {
                label: type,
                value: type,
            };
        }
    });

    let langCodes = PubMedLanguages;
    if (source === Search.GOOGLE) {
        langCodes = GoogleLanguages;
    }

    const languages = langCodes.map((code) => {
        return {
            label: LanguageCodes[code],
            value: code,
        };
    });

    /**
     * Compose the sidebar filters
     */
    const Filters = observer(() => {
        if (source === Search.PUBMED) {
            return (
                <>
                    <Filter.DateRange
                        filter={filter}
                        title="Publication Date"
                        startDateName="minPublicationDate"
                        endDateName="maxPublicationDate"
                        disabled={disabled}
                    />

                    <Filter.NumberInput
                        filter={filter}
                        title="Limit Results"
                        name="maxResults"
                        disabled={disabled}
                    />

                    <Filter.MultiSelect
                        filter={filter}
                        title="Article Type"
                        name="publicationTypes"
                        options={publicationTypes}
                        disabled={disabled}
                    />

                    <Filter.OptionToggle
                        filter={filter}
                        title="Species"
                        name="humanOnly"
                        label="Human"
                        disabled={disabled}
                    />

                    <Filter.MultiSelect
                        filter={filter}
                        title="Language"
                        name="languageCode"
                        options={languages}
                        disabled={disabled}
                    />

                    <Filter.OptionToggle
                        filter={filter}
                        title="Search Behavior"
                        name="excludeBooks"
                        label="Exclude Books"
                        disabled={disabled}
                    />

                    <Filter.OptionToggle
                        filter={filter}
                        title="Journal"
                        name="medline"
                        label="Medline"
                        disabled={disabled}
                    />

                    {/* <div className="filter-group">
                        <div className="title">Applied Filters</div>
                        <div className="text">Language: English only</div>
                    </div> */}

                    <div className="filter-group">
                        <div className="title">Sorted By</div>
                        <div className="text">Relevance</div>
                    </div>
                </>
            );
        } else if (source === Search.EUPMC) {
            return (
                <>
                    <Filter.DateRange
                        filter={filter}
                        title="Publication Date"
                        startDateName="minPublicationDate"
                        endDateName="maxPublicationDate"
                        disabled={disabled}
                    />

                    <Filter.NumberInput
                        filter={filter}
                        title="Limit Results"
                        name="maxResults"
                        disabled={disabled}
                    />

                    <Filter.MultiSelect
                        filter={filter}
                        title="Article Type"
                        name="publicationTypes"
                        options={publicationTypes}
                        disabled={disabled}
                    />

                    <Filter.OptionToggle
                        filter={filter}
                        title="MeSH Synonym Expansion"
                        name="expandSynonyms"
                        label="Expand Synonyms"
                        disabled={disabled}
                    />

                    <Filter.MultiSelect
                        filter={filter}
                        title="Language"
                        name="languageCode"
                        options={languages}
                        disabled={disabled}
                    />

                    <div className="filter-group">
                        <div className="title">Sorted By</div>
                        <div className="text">Relevance</div>
                    </div>
                </>
            );
        } else if (source === Search.GOOGLE) {
            return (
                <>
                    <Filter.MinMax
                        filter={filter}
                        title="Year"
                        name="year"
                        min={minYear}
                        max={maxYear}
                        disabled={disabled}
                    />

                    <Filter.NumberInput
                        filter={filter}
                        title="Limit Results"
                        name="maxResults"
                        disabled={disabled}
                    />

                    <Filter.MultiSelect
                        filter={filter}
                        title="Language"
                        name="languageCode"
                        options={languages}
                        disabled={disabled}
                    />

                    <div className="filter-group">
                        <div className="title">Applied Filters</div>
                        <div className="text">Exclude Patents</div>
                        <div className="text">Exclude Citations</div>
                    </div>
                    <div className="filter-group">
                        <div className="title">Sorted By</div>
                        <div className="text">Relevance</div>
                    </div>
                </>
            );
        } else {
            return (
                <>
                    <Filter.Date
                        filter={filter}
                        title="Performed On"
                        name="performedOn"
                        disabled={disabled}
                    />
                    <Filter.DateRange
                        filter={filter}
                        title="Publication Date"
                        startDateName="minPublicationDate"
                        endDateName="maxPublicationDate"
                        disabled={disabled}
                    />
                    <Filter.MultiSelect
                        filter={filter}
                        title="Article Type"
                        name="publicationTypes"
                        options={publicationTypes}
                        disabled={disabled}
                    />
                    <Filter.OptionToggle
                        filter={filter}
                        title="Species"
                        name="humanOnly"
                        label="Human"
                        disabled={disabled}
                    />
                    <Filter.MultiSelect
                        filter={filter}
                        title="Language"
                        name="languageCode"
                        options={languages}
                        disabled={disabled}
                    />
                </>
            );
        }
    });

    /**
     * Compose the sidebar template download section
     */
    const Template = observer(() => {
        if (source === Search.OTHER) {
            return (
                <>
                    <div className="filter-group sample-template">
                        <div className="title">Template</div>
                        <div className="text">
                            You can download an example template below:
                            <div className="download">
                                <a href={template} target="_blank" rel="noreferrer">
                                    <Button icon={<FileProtectOutlined />}>Example Template</Button>
                                </a>
                            </div>
                        </div>
                    </div>
                </>
            );
        }
    });

    return (
        <Sidebar filter={filter}>
            <Filters />
            <Template />
        </Sidebar>
    );
});

export default QueryFilter;
