import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { CloseOutlined } from "@ant-design/icons";
import { Button, Form, Input, Select } from "antd";
import { useParams } from "react-router-dom";

import Box from "@app/components/box/box";
import ImageUpload from "@app/components/form/upload/image";
import { Page } from "@app/components/page";
import config from "@app/config";
import notify from "@app/components/notify";
import session from "@app/state/store/session";

import user from "@app/state/store/users/details";
import "./style/edit.scoped.scss";
import { Role } from "@app/constants";

const Details = observer((props) => {
    const [form] = Form.useForm();
    const { id } = useParams();
    const [isClient, setIsClient] = useState(false);

    // initialize the state when component mounts. It is triggered
    // only when the id parameter changes so it will load the
    // user details corresponding to the passed id
    useEffect(() => {
        (async () => {
            if (id) {
                await user.load(id);
                setIsClient(user.data?.roles.includes(Role.CLIENT));
            } else {
                user.create();
            }

            // update the form fields after the load is complete
            form.setFieldsValue(user.data);
            user.loadClients();
        })();
    }, [id, form]);

    // return empty page while loading
    if (user.data?._id !== id) {
        return null;
    }

    /**
     * Save the user
     * @param {Object} data
     */
    const save = async (data) => {
        const success = await user.update(data);
        if (success) {
            close();
        }
    };

    /**
     * Close the page
     */
    const close = () => {
        props.history.push("/users");
    };

    /**
     * Show the validation error
     */
    const validationError = () => {
        notify.error("Please fill all required fields to continue");
    };

    /**
     * Validate that the client role is not selected with other roles
     */
    const changeRoles = (roles) => {
        let isClient = roles.includes(Role.CLIENT);
        setIsClient(isClient);

        if (isClient) {
            roles = [Role.CLIENT];
            form.setFieldsValue({ roles });
        } else {
            form.setFieldsValue({ clients: [] });
        }
    };

    const isAdmin = session.can("system.admin");

    return (
        <Page className="edit">
            <Page.Header>
                <Page.Header.Left>
                    <Page.Title>{"Edit User"}</Page.Title>
                </Page.Header.Left>

                <Page.Header.Right>
                    <Button
                        type="icon"
                        loading={user.busy}
                        onClick={close}
                        icon={<CloseOutlined />}
                    ></Button>
                </Page.Header.Right>
            </Page.Header>

            <Page.Body>
                <Box className="content-box">
                    <Form
                        layout="vertical"
                        initialValues={user.data}
                        form={form}
                        onFinishFailed={validationError}
                        onFinish={save}
                    >
                        <Form.Item name="avatar">
                            <ImageUpload file={user.data?.avatar} className="m-auto" share="true" />
                        </Form.Item>

                        <Form.Item
                            label="First Name"
                            name="firstName"
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter the user's first name",
                                },
                            ]}
                        >
                            <Input autoComplete="first-name" />
                        </Form.Item>

                        <Form.Item
                            label="Last Name"
                            name="lastName"
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter the user's last name",
                                },
                            ]}
                        >
                            <Input autoComplete="last-name" />
                        </Form.Item>

                        <Form.Item
                            label="Email"
                            name="email"
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter the user's email",
                                },
                                {
                                    type: "email",
                                    message: "Invalid email address",
                                },
                            ]}
                        >
                            <Input autoComplete="email" />
                        </Form.Item>

                        <Form.Item
                            label="Roles"
                            name="roles"
                            rules={[
                                {
                                    required: true,
                                    message: "Please assign a role",
                                },
                            ]}
                        >
                            <Select
                                mode="multiple"
                                optionFilterProp="label"
                                placeholder="Please select roles"
                                onChange={changeRoles}
                            >
                                {config.roles
                                    .filter((role) => (isAdmin ? role : !role.viewOnly))
                                    .map((role) => {
                                        return (
                                            <Select.Option
                                                key={role.value}
                                                value={role.value}
                                                label={role.label}
                                            >
                                                {role.label}
                                            </Select.Option>
                                        );
                                    })}
                            </Select>
                        </Form.Item>

                        {isClient && (
                            <Form.Item
                                label="Clients"
                                name="clients"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please pick a client",
                                    },
                                ]}
                            >
                                <Select mode="multiple" placeholder="Please pick a client">
                                    {user.clients.map((client) => {
                                        return (
                                            <Select.Option key={client._id} value={client._id}>
                                                {client.name}
                                            </Select.Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>
                        )}
                    </Form>
                </Box>
            </Page.Body>

            <Page.Footer>
                <Page.Footer.Left>
                    <div className="button-group">
                        <Button type="primary" loading={user.busy} onClick={() => form.submit()}>
                            Save
                        </Button>
                        <Button onClick={close}>Cancel</Button>
                    </div>
                </Page.Footer.Left>
            </Page.Footer>
        </Page>
    );
});

export default Details;
