import React from "react";
import { observer, useLocalStore } from "mobx-react";
import { Link } from "react-router-dom";

import { Form, Button, Input, Checkbox, Alert } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";

import "./style/login.scoped.scss";

import session from "@app/state/store/session";

const Login = observer(() => {
    const [form] = Form.useForm();
    const history = useHistory();

    var state = useLocalStore(() => ({
        error: false,
        loading: false,
    }));

    /**
     * Hide the error message
     */
    const hideError = () => {
        state.error = false;
    };

    /**
     * Perform the authentication
     * @param {Object} args
     */
    const login = async (args) => {
        state.loading = true;
        const result = await session.login(args);
        state.loading = false;

        if (result === null) {
            state.error = false;
        } else if (!session.mfaPending) {
            // show the error
            state.error = true;
        } else {
            history.push("/auth/mfa");
        }
    };

    // render the page
    return (
        <div className="login auth">
            <div className="logo">
                <img src={require("@app/assets/logo-name.svg")} />
            </div>

            {state.error ? (
                <Alert
                    className="alert"
                    message="Authentication Failed"
                    description="Invalid email or password"
                    type="error"
                    showIcon
                />
            ) : null}

            <Form layout="vertical" form={form} onFinish={login} onFieldsChange={hideError}>
                <div className="form-title">Sign In</div>

                <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                        {
                            required: true,
                            message: "Please enter your email",
                        },
                        {
                            type: "email",
                            message: "Invalid email address",
                        },
                    ]}
                >
                    <Input autoComplete="username" prefix={<UserOutlined />} />
                </Form.Item>

                <Form.Item
                    label="Password"
                    name="password"
                    rules={[{ required: true, message: "Please enter your password!" }]}
                >
                    <Input.Password autoComplete="current-password" prefix={<LockOutlined />} />
                </Form.Item>

                <div className="row">
                    <div className="col">
                        <Form.Item name="remember" valuePropName="checked">
                            <Checkbox>Remember me</Checkbox>
                        </Form.Item>
                    </div>
                    <div className="col forgotten-password">
                        <Link to="./password">Forgot your password?</Link>
                    </div>
                </div>

                <Form.Item>
                    <Button type="primary" size="large" loading={state.loading} htmlType="submit">
                        Sign In
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
});

export default Login;
