import { observable, computed } from "mobx";
import Model from "@app/state/model/base";
import format from "@app/lib/format";

export default class User extends Model {
    @observable _id = undefined;
    @observable firstName = undefined;
    @observable lastName = undefined;
    @observable fullName = undefined;
    @observable avatar = undefined;
    @observable email = undefined;
    @observable roles = [];
    @observable online = false;
    @observable $selected = false;
    @observable termsAccepted = true;

    /**
     * Return user's initials
     */
    @computed get initials() {
        return format.initials(this.fullName);
    }

    /**
     * Check if an user belongs to a role
     * @param {String} role
     */
    is(role) {
        if (!role) {
            return false;
        }

        return this.roles.indexOf(role) >= 0;
    }
}
