import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { Link, useParams } from "react-router-dom";
import { Button } from "antd";
import { CloseOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";

import confirm from "@app/components/confirm";
import { Page } from "@app/components/page";
import UserDetails from "@app/components/user/details";

import user from "@app/state/store/users/details";
import "./style/view.scoped.scss";

const Details = observer((props) => {
    const { id } = useParams();
    const isPending = user.data?.status === "pending";

    // load the list when the component mounts
    useEffect(() => {
        user.load(id);
    }, [id]);

    // return empty page while loading
    if (user.data?._id !== id) {
        return null;
    }

    /**
     * Close the page
     */
    const close = () => {
        props.history.push("/users");
    };

    /**
     * Delete the user
     */
    const remove = async () => {
        let proceed = await confirm("Are you sure you want to remove this user?");
        if (proceed) {
            await user.remove();
            close();
        }
    };

    return (
        <Page className="details">
            <Page.Header>
                <Page.Header.Left>
                    <Page.Title>User Details</Page.Title>
                </Page.Header.Left>

                <Page.Header.Right>
                    <div className="icon-group">
                        {!isPending && (
                            <Link to={`${props.match.url}/edit`}>
                                <Button
                                    loading={user.busy}
                                    type="icon"
                                    icon={<EditOutlined />}
                                ></Button>
                            </Link>
                        )}
                        <Button
                            type="icon"
                            loading={user.busy}
                            onClick={remove}
                            icon={<DeleteOutlined />}
                            danger
                        ></Button>
                        <Button
                            type="icon"
                            loading={user.busy}
                            onClick={close}
                            icon={<CloseOutlined />}
                        ></Button>
                    </div>
                </Page.Header.Right>
            </Page.Header>
            <Page.Body>
                <UserDetails className="content-box" user={user.data} />
            </Page.Body>
        </Page>
    );
});

export default Details;
