import React, { useEffect, useState } from "react";
import { useQuill } from "react-quilljs";

import "quill/dist/quill.snow.css";
import "./style/additionalData.scoped.scss";

const TextInput = ({ value = "", onChange, readOnly, onBlur, children, ...rest }) => {
    const options = {
        theme: "snow",
        modules: {
            toolbar: [
                ["bold", "italic"],
                [{ script: "sub" }, { script: "super" }],
                [{ list: "ordered" }, { list: "bullet" }],
            ],
            clipboard: {
                matchVisual: false,
            },
        },
        formats: ["bold", "italic", "script", "list"],
        placeholder: "",
        readOnly,
    };

    if (readOnly) {
        options.modules.toolbar = null;
    }

    const { quill, quillRef } = useQuill(options);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        if (quill && !loaded) {
            const content = quill.clipboard.convert(value);
            quill.setContents(content);

            const textChangeHandler = () => {
                const html = quill.container.firstChild.innerHTML;
                onChange && onChange(html);
            };
            quill.on("text-change", textChangeHandler);
            setLoaded(true);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [quill, value, loaded, onChange]);

    return (
        <div className="textInput" {...rest}>
            <div
                ref={quillRef}
                tabIndex="0"
                onBlur={(e) => {
                    onBlur && onBlur(e.currentTarget.firstChild.innerHTML);
                }}
            />
            {children}
        </div>
    );
};

export default TextInput;
