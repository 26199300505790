import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { CloseOutlined, PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import { Button, Form, Input, Select } from "antd";

import Box from "@app/components/box/box";
import { Page } from "@app/components/page";
import config from "@app/config";
import notify from "@app/components/notify";
import session from "@app/state/store/session";

import user from "@app/state/store/users/details";
import "./style/invite.scoped.scss";
import { Role } from "@app/constants";

const Details = observer((props) => {
    const [form] = Form.useForm();
    const [uId, setUId] = useState(1);
    const [inputFields, setInputFields] = useState([{ email: "", roles: "", uId: uId }]);

    // Initialize the state when component mounts
    useEffect(() => {
        user.loadClients();
    }, []);

    /**
     * handle role change
     */
    const handleRoleChange = (roles, index) => {
        const isClient = roles.includes(Role.CLIENT);
        const list = [...inputFields];

        // remove all additional roles
        if (isClient) {
            roles = [Role.CLIENT];
            form.setFieldsValue({ [`role${list[index].uId}`]: roles });
        }

        list[index]["roles"] = roles;
        list[index]["isClient"] = isClient;

        setInputFields(list);

        if (index === inputFields.length - 1) {
            handleAddFields();
        }
    };

    /**
     * handle input field change
     */
    const handleChange = (e, index) => {
        const { value } = e.target;
        const list = [...inputFields];
        list[index]["email"] = value;

        setInputFields(list);

        if (index === inputFields.length - 1) {
            handleAddFields();
        }
    };

    /**
     * handle client field change
     */
    const handleClientChange = (clients, index) => {
        const list = [...inputFields];
        list[index]["clients"] = clients;

        setInputFields(list);

        if (index === inputFields.length - 1) {
            handleAddFields();
        }
    };

    /**
     * handle click event of the Add Fields
     */
    const handleAddFields = () => {
        setInputFields([...inputFields, { email: "", roles: "", uId: uId + 1 }]);
        setUId(uId + 1);
    };

    /**
     * handle click event of the Remove button
     */
    const handleRemoveFields = (index) => {
        const list = [...inputFields];
        list.splice(index, 1);

        setInputFields(list);
    };

    /**
     * Send the invite
     * @param {Object} data
     */
    const send = async () => {
        //  Show the validation error
        if (inputFields[0].email.length === 0 || inputFields[0].roles.length === 0) {
            notify.error("Please add at least one user to continue");
        } else {
            let checkFields = inputFields.filter(
                (rec, index, self) =>
                    index ===
                    self.findIndex(
                        (t) =>
                            t.email === rec.email && rec.email.length > 0 && rec.roles.length > 0,
                    ),
            );

            const success = await user.invite({ users: checkFields });
            if (success) {
                close();
            }
        }
    };

    /**
     * Close the page
     */
    const close = () => {
        props.history.push("/users");
    };

    const isAdmin = session.can("system.admin");

    return (
        <Page className="invite">
            <Page.Header>
                <Page.Header.Left>
                    <Page.Title>Invite User</Page.Title>
                </Page.Header.Left>

                <Page.Header.Right>
                    <Button
                        type="icon"
                        loading={user.busy}
                        onClick={close}
                        icon={<CloseOutlined />}
                    ></Button>
                </Page.Header.Right>
            </Page.Header>

            <Page.Body>
                <Box className="content-box">
                    <Form layout="vertical" initialValues={user.data} form={form} onFinish={send}>
                        <div className="labels">
                            <div className="email">Email</div>
                            <div className="role">Roles</div>
                            <Button
                                type="icon"
                                icon={<PlusOutlined />}
                                onClick={() => handleAddFields()}
                            />
                        </div>
                        <div className="grid">
                            {inputFields.map((inputField, index) => {
                                return (
                                    <div className="user" key={index}>
                                        <div className="row gap">
                                            <div className="col">
                                                <Form.Item
                                                    name={"email" + inputField.uId}
                                                    rules={[
                                                        {
                                                            type: "email",
                                                            message: "Invalid email address",
                                                        },
                                                    ]}
                                                >
                                                    <Input
                                                        onChange={(e) => handleChange(e, index)}
                                                        value={inputField.email}
                                                        placeholder="Please enter email"
                                                    />
                                                </Form.Item>
                                            </div>
                                            <div className="col">
                                                <Form.Item
                                                    name={"role" + inputField.uId}
                                                    className="col"
                                                    rules={[
                                                        {
                                                            required: inputField.email !== "",
                                                            message: "Please pick the user's role",
                                                        },
                                                    ]}
                                                >
                                                    <Select
                                                        mode="multiple"
                                                        value={inputField.role}
                                                        optionFilterProp="label"
                                                        onChange={(e) => handleRoleChange(e, index)}
                                                        placeholder="Please select roles"
                                                    >
                                                        {config.roles
                                                            // .filter((role) => !role.viewOnly)
                                                            .filter((role) =>
                                                                isAdmin ? role : !role.viewOnly,
                                                            )
                                                            .map((role) => {
                                                                return (
                                                                    <Select.Option
                                                                        key={role.value}
                                                                        value={role.value}
                                                                        label={role.label}
                                                                    >
                                                                        {role.label}
                                                                    </Select.Option>
                                                                );
                                                            })}
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                            <div className="col auto">
                                                <Button
                                                    type="icon"
                                                    icon={<DeleteOutlined />}
                                                    onClick={() => handleRemoveFields(index)}
                                                />
                                            </div>
                                        </div>
                                        {inputField.isClient && (
                                            <div className="row gap clients">
                                                <div className="col">
                                                    <Form.Item
                                                        name={"clients" + inputField.uId}
                                                        className="col"
                                                        rules={[
                                                            {
                                                                required: inputField.email !== "",
                                                                message: "Please pick a client",
                                                            },
                                                        ]}
                                                    >
                                                        <Select
                                                            mode="multiple"
                                                            value={inputField.clients}
                                                            optionFilterProp="label"
                                                            onChange={(e) =>
                                                                handleClientChange(e, index)
                                                            }
                                                            placeholder="Please select a client"
                                                        >
                                                            {user.clients.map((client) => {
                                                                return (
                                                                    <Select.Option
                                                                        key={client._id}
                                                                        value={client._id}
                                                                        label={client.name}
                                                                    >
                                                                        {client.name}
                                                                    </Select.Option>
                                                                );
                                                            })}
                                                        </Select>
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                );
                            })}
                        </div>
                    </Form>
                </Box>
            </Page.Body>

            <Page.Footer>
                <Page.Footer.Left>
                    <div className="button-group">
                        <Button type="primary" loading={user.busy} onClick={() => form.submit()}>
                            Send
                        </Button>
                        <Button onClick={close}>Cancel</Button>
                    </div>
                </Page.Footer.Left>
            </Page.Footer>
        </Page>
    );
});

export default Details;
