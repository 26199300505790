import React from "react";
import { observer } from "mobx-react";
import { Form, Button, Input } from "antd";
import { CloseOutlined } from "@ant-design/icons";

import { Page } from "@app/components/page";
import ImageUpload from "@app/components/form/upload/image";
import Box from "@app/components/box/box";
import notify from "@app/components/notify";

import profile from "@app/state/store/profile";
import "./style/edit.scoped.scss";

const EditProfile = observer((props) => {
    const [form] = Form.useForm();

    // return empty page while loading
    if (!profile.data) {
        profile.load();
        return null;
    }

    /**
     * Save the user
     * @param {Object} data
     */
    const save = async (data) => {
        const success = await profile.save(data);
        if (success) {
            close();
        }
    };

    /**
     * Close the page
     */
    const close = () => {
        props.history.push("../profile");
    };

    /**
     * Show the validation error
     */
    const validationError = () => {
        notify.error("Please fill all required fields to continue");
    };

    return (
        <Page className="profile-edit">
            <Page.Header>
                <Page.Header.Left>
                    <Page.Title>Edit Your Profile</Page.Title>
                </Page.Header.Left>
                <Page.Header.Right>
                    <Button
                        type="icon"
                        loading={profile.busy}
                        icon={<CloseOutlined />}
                        onClick={close}
                    ></Button>
                </Page.Header.Right>
            </Page.Header>
            <Page.Body>
                <Box className="content-box">
                    <Form
                        layout="vertical"
                        initialValues={profile.data}
                        form={form}
                        onFinishFailed={validationError}
                        onFinish={save}
                    >
                        <Form.Item name="avatar">
                            <ImageUpload
                                file={profile.data?.avatar}
                                className="m-auto"
                                share="true"
                            />
                        </Form.Item>

                        <Form.Item
                            label="First Name"
                            name="firstName"
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter your first name",
                                },
                            ]}
                        >
                            <Input autoComplete="first-name" />
                        </Form.Item>

                        <Form.Item
                            label="Last Name"
                            name="lastName"
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter your last name",
                                },
                            ]}
                        >
                            <Input autoComplete="first-name" />
                        </Form.Item>

                        <Form.Item
                            label="Email"
                            name="email"
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter your email",
                                },
                                {
                                    type: "email",
                                    message: "Invalid email address",
                                },
                            ]}
                        >
                            <Input autoComplete="email" />
                        </Form.Item>
                    </Form>
                </Box>
            </Page.Body>
            <Page.Footer>
                <Page.Footer.Left>
                    <div className="button-group">
                        <Button type="primary" loading={profile.busy} onClick={() => form.submit()}>
                            Update
                        </Button>
                        <Button onClick={close}>Cancel</Button>
                    </div>
                </Page.Footer.Left>
            </Page.Footer>
        </Page>
    );
});

export default EditProfile;
