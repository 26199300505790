import { action, computed } from "mobx";
import http from "@app/lib/http";
import BaseStore from "./base";
import SessionStore from "./session";
import ApplicationSettings from "@app/state/model/application/settings";
import notify from "@app/components/notify/index";

export class Application extends BaseStore {
    observable() {
        return {
            settings: null,
        };
    }

    @computed get enableAI() {
        return this.settings.ai?.enabled && SessionStore.can("copilot");
    }

    @action
    async load() {
        if (this.settings) {
            return;
        }

        try {
            const { data } = await http.get(`/application/settings`);
            this.settings = new ApplicationSettings(data);
        } catch (error) {
            notify.error(error.response?.data?.error);
        }
    }
}

export default new Application();
